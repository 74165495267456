import request from "./request";

//商品导航
export function navigation(data) {
  return request({
    url: "/product/navigation",
    method: "post",
    data,
  });
}

//商品导航列表
export function mobilelist(data) {
  return request({
    url: "/product/mobilelist",
    method: "post",
    data,
  });
}

//商品列表
export function list(data) {
  return request({
    url: "/product/list",
    method: "post",
    data,
  });
}

//商品搜索
export function search(data) {
  return request({
    url: "/product/search",
    method: "post",
    data,
  });
}

// 版权实务
export function getPracticelist(data) {
  return request({
    url: "/product/page",
    method: "post",
    data,
  });
}

// 分页搜索订单
export function findSideProductList() {
  return request({
    url: "/side/findSideProductList",
    method: "get",
  });
}
