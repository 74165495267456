<template>
  <div class="shoppingMallS">
    <div class="box">
      <div class="content_box">
        <div class="right_box">
          <!-- nav -->
          <div class="shoppingMall-banner">
            <div class="shoppingMall-banner-swiper">
              <el-carousel
                :interval="5000"
                height="200px"
                trigger="click"
                arrow="hover"
              >
                <el-carousel-item
                  v-for="(item, index) in bannerList"
                  :key="item.id"
                >
                  <img
                    @click="bannerClick(item.id, index)"
                    :src="item.imgUrl"
                    alt=""
                  />
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>

          <!-- 排序 -->
          <!-- <div class="shoppingMall-sortBox">
            <div class="sort">
              <div
                class="right-item"
                v-for="(item, index) in sortArr"
                :key="index"
                @click="handleClick(item)"
              >
                <div
                  class="right-item-text"
                  :class="item.colorSwitch == true ? 'fontColor' : ''"
                >
                  {{ item.title }}
                </div>
                <i
                  class="right-item-icon"
                  :class="item.sort == true ? 'up' : 'down'"
                ></i>
              </div>
            </div>

            <div class="shoppingMall-total">
              <span>{{ total }}</span
              >个商品
            </div>
          </div> -->

          <!-- 商品 -->
          <div class="shoppingMall-content" id="shoppingMall-content">
            <div class="kong" v-if="goodsList.length == 0">
              <img :src="require('assets/images/no-shop.png')" alt="" />
              暂无商品
            </div>
            <div
              v-else
              class="shoppingMall-content-item"
              v-for="(item, index) in goodsList"
              :key="index"
              @click="itemClick(item)"
            >
              <img
                :src="$store.state.onlineBasePath + item.productIntroduction"
                alt=""
              />
              <div class="item-title">
                <div class="item-title-left">自营</div>
                <div class="item-title-text">{{ item.productName }}</div>
              </div>
              <div class="item-price">
                <div class="proces">¥ {{ item.productPrices }}</div>
                <div
                  class="vip_proces"
                  v-if="item.productPrices != 0 && item.memberPrices"
                >
                  VIP/SVIP会员: {{ item.memberPrices }}
                </div>
              </div>
              <!-- <div class="item-business">深圳写手智能科技有限公司</div> -->
            </div>
            <div class="advice" v-for="item in 4" :key="item"></div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :modal="false"
      :visible.sync="showIntelliRecom"
      class="intelli-recom"
    >
      <div class="title">智能推文体验</div>
      <div class="desc">
        默认搭配“通用中文语境算法引擎”，更好体验请下载
        <span class="blue" @click="downIdiom">&nbsp;成语</span>
      </div>
      <IntelligentRecommendation />
    </el-dialog>
  </div>
</template>

<script>
import {
  navigation,
  mobilelist,
  list,
  search,
  getPracticelist,
} from "api/shoppingMall";
import commonFooter from "components/footer/commonFooter.vue";
import IntelligentRecommendation from "views/idiom/Idiom";

export default {
  name: "shoppingMallS",
  data() {
    return {
      openeds: [],
      input: "", //搜索关键字
      wordsIndex: -1,

      sortArr: [
        {
          title: "综合",
          sort: false,
          colorSwitch: true,
        },
        {
          title: "销量",
          sort: true,
          colorSwitch: false,
        },
        {
          title: "新品",
          sort: true,
          colorSwitch: false,
        },
        {
          title: "价格",
          sort: true,
          colorSwitch: false,
        },
        {
          title: "评论数",
          sort: true,
          colorSwitch: false,
        },
      ],
      bannerList: [
        //轮播图list

        {
          id: 2,
          imgUrl: require("assets/images/shopping_banner01.png"),
        },

        {
          id: 4,
          imgUrl: require("assets/images/shopping_banner02.png"),
        },
        {
          id: 3,
          imgUrl: require("assets/images/shopping_banner03.png"),
        },
      ],
      recommendList: [
        //推荐list
        {
          id: 1,
          name: "语境算法引擎",
          active: false,
        },
        {
          id: 2,
          name: "金句库",
          active: false,
        },
        {
          id: 3,
          name: "模板库",
          active: false,
        },
        {
          id: 914,
          name: "公文模板库",
          active: false,
        },
        {
          id: 915,
          name: "法律文书模板库",
          active: false,
        },
        {
          id: 920,
          name: "劳动类合同协议",
          active: false,
        },
      ],
      sortList: [
        // {
        //   id:1,
        //   name:"综合",
        //   sort:"default",
        // },
        // {
        //   id:2,
        //   name:"销量",
        //   sort:"default",
        // },
        {
          id: 3,
          name: "新品",
          sort: 0, //1正序 2倒叙 0默认
        },
        {
          id: 4,
          name: "价格",
          sort: 0, //1正序 2倒叙 0默认
        },
        // {
        //   id:5,
        //   name:"评论数",
        //   sort:"default",
        // },
      ],
      timeSort: "", //新品排序
      priceSort: "", //价格排序
      total: null, //商品条数
      goodsList: [], //商品list
      id: 0, //商品id
      recommendIndex: -1,
      menuList: [],
      // 是否粘性布局
      isFixed: false,
      // 是否触发锚点
      isAnchor: true,
      //显示智能推文
      showIntelliRecom: false,
    };
  },
  methods: {
    bannerClick(id, index) {
      if (index == 1) {
        this.$router.push({
          name: "details",
          params: { id: 1 },
        });
      }

      if (index == 2) {
        this.$router.push({
          name: "downloadS",
          params: { id: 1 },
        });
      }
    },
    downIdiom() {
      this.$router.push({
        path: "/downloadGuide",
      });
    },
    navClick(item) {
      if (this.isAnchor) {
        let PageId = document.querySelector("#shoppingMall-content");
        window.scrollTo({
          top: PageId.offsetTop,
          behavior: "smooth",
        });
      }

      this.id = item.id;
      if (this.id == 4) {
        this.GetPracticelist(1);
        return;
      } else if (this.id == 5) {
        this.GetPracticelist(2);
        return;
      } else if (this.id == 8) {
        this.GetPracticelist(8);
        return;
      }
      this.getLeftList();
    },
    openNav(id) {
      let arr = id.split(",");
      this.id = arr[0];
      if (this.id == 4) {
        this.GetPracticelist(1);
        return;
      } else if (this.id == 5) {
        this.GetPracticelist(2);
        return;
      } else if (this.id == 8) {
        this.GetPracticelist(8);
        return;
      }
      this.getLeftList();
    },
    wordsClick(item, i) {
      this.wordsIndex = i;

      item.active = !item.active;
      if (item.id == 1) {
        this.$router.push({
          name: "details",
          params: { id: item.id, type: item.type },
        });
      } else if (item.id == 2) {
        this.$router.push({
          name: "details",
          params: { id: item.id, type: item.type },
        });
      } else {
        this.id = item.id;
        this.getLeftList();
        if (this.isAnchor) {
          let PageId = document.querySelector("#shoppingMall-content");
          window.scrollTo({
            top: PageId.offsetTop,
            behavior: "smooth",
          });
        }
      }
    },
    // 排序
    sortClicli(item) {
      console.log(item);
      if (item.sort >= 2) {
        item.sort = 0;
      } else {
        item.sort++;
      }
      if (item.sort == 0) {
        return;
      }
      if (item.id == 3) {
        this.timeSort = item.sort;
        this.GetPracticelist(1);
      } else if (item.id == 4) {
        this.priceSort = item.sort;
        this.GetPracticelist(1);
      }
    },
    //推荐
    recommendClick(item, i) {
      this.recommendIndex = i;
      item.active = !item.active;
      this.$forceUpdate();
      if (item.id == 1 || item.id == 2 || item.id == 3) {
        this.id = item.id;
        this.getLeftList();
      } else {
        this.$router.push({
          name: "details",
          params: { id: item.id, type: item.type },
        });
      }
    },

    // 版权实务list
    async GetPracticelist(val) {
      const data = {
        page: 1,
        limit: 100,
        type: val,
        key: this.input,
      };

      const res = await getPracticelist(data);
      // console.log(res);
      if (res.code != 200) return;

      this.goodsList = res.data.data;
      this.total = res.data.total;
    },

    // 左侧导航栏
    async getLeftList() {
      let formData = new FormData();
      formData.append("id", this.id);
      formData.append("priceSort", this.priceSort);
      formData.append("timeSort", this.timeSort);
      const res = await list(formData);
      if (res.code === 200) {
        this.goodsList = res.data.products;
        this.total = res.data.total;
      }
    },

    // 初始化数据
    async getList() {
      let formData = new FormData();
      formData.append("id", this.id);
      formData.append("priceSort", this.priceSort);
      formData.append("timeSort", this.timeSort);
      const res = await list(formData);
      if (res.code === 200) {
        this.goodsList = res.data.products;
        this.total = res.data.total;
      }
    },

    itemClick(item) {
      let routeData;

      if (item.productType == 4) {
        this.$router.push({
          name: "copyrightDetails",
          params: {
            id: item.id,
          },
        });
      } else {
        this.$router.push({
          name: "details",
          params: {
            id: item.id,
          },
        });
      }

      // window.open(routeData.href, 'newWindow', 'top=0'); //打开新窗口
    },
    //筛选排序
    handleClick(item) {
      this.sortArr.forEach((item) => {
        item.colorSwitch = false;
      });
      item.colorSwitch = !item.colorSwitch;
      item.sort = !item.sort;
    },
    // 搜索
    async search() {
      this.GetPracticelist(1);
      // if (this.isAnchor) {
      //   let PageId = document.querySelector("#shoppingMall-content");
      //   window.scrollTo({
      //     top: PageId.offsetTop,
      //     behavior: "smooth",
      //   });
      // }
      // let formData = new FormData();
      // formData.append("productName", this.input);
      // const res = await search(formData);
      // if (res.code === 200) {
      //   this.goodsList = res.data.products;
      //   this.total = res.data.total;
      // }
    },
    async enter(item) {
      this.timer = setTimeout(() => {
        if (item.id == 1 || item.id == 2 || item.id == 3) {
          this.getNavItem(item.id);
        } else if (item.id == 5) {
          this.navItemList = [
            {
              name: "专业服务",
              id: 5,
              children: [
                {
                  name: "版权实务",
                  type: 2,
                  id: 1,
                },
                {
                  name: "书法字画",
                  type: 0,
                  id: 2,
                },
              ],
            },
          ];
          this.isshow = true;
        } else if (item.id == 4) {
          this.navItemList = [
            {
              name: "会员服务",
              id: 4,
              children: [
                {
                  name: "会员服务",
                  type: 1,
                  id: 1,
                },
              ],
            },
          ];
          this.isshow = true;
        } else {
          return;
        }
      });
    },

    // 监听滚动条
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      // 是否粘性布局
      if (scrollTop > 210) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }

      // 是否触发锚点
      if (scrollTop > 500) {
        this.isAnchor = false;
      } else {
        this.isAnchor = true;
      }
    },
  },
  components: {
    IntelligentRecommendation,
  },
  created() {
    // this.getList();
    this.GetPracticelist(1);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="scss" scoped>
.shoppingMallS {
  height: calc(100vh - 100px);
  .box {
    display: flex;
    max-width: 1338px;
    min-width: 800px;
    box-sizing: border-box;
    margin: 0 auto;

    // .left {
    //   width: 1036px;
    //   margin-right: 20px;
    //   background-color: #fff;
    //   display: flex;
    //   position: relative;
    // }
    // .right {
    //   background-color: #fff;
    //   flex: 1;
    // }
  }
  .content_box {
    margin-top: 40px;
    display: flex;
    .left_box {
      margin-right: 10px;
      width: 237px;
      position: relative;
      .menu {
        width: 237px;
        position: fixed;
        top: 0;
      }
      ::v-deep .el-menu {
        border-right: solid 0px;
        height: 100%;
        .el-menu-item.is-active {
          background-color: #ffbf6914;
        }
        .el-menu-item {
          &:hover {
            color: #ff6900;
            background-color: #ffbf6914 !important;
          }
        }
        .el-submenu {
          .el-submenu__title {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #333333;
            &:hover {
              color: #ff6900;
              background-color: #ffbf6914 !important;
            }
          }
          .el-submenu__title:hover {
            background-color: #e1ecff;
          }
        }
        img {
          width: 19px;
          height: 19px;
          margin-right: 13px;
          image-rendering: -webkit-optimize-contrast;
        }
      }
    }

    .right_box {
      flex: 1;
    }
  }
  .shoppingMall-header {
    position: relative;
    padding: 30px 0 0 240px;
    &-intelligent-recom {
      @include flex-center(column);
      justify-content: stretch;
      align-items: stretch;
      position: absolute;
      left: 20px;
      top: 46px;
      cursor: pointer;
      > div {
        @include flex-start;
        .text {
          margin-left: 5px;
          font-size: 16px;
          font-weight: 500;
          color: #333333;
        }
        &:last-child {
          margin-top: 14px;
        }
      }
    }
    &-top {
      display: flex;
      align-items: center;
      .shoppignMall-searchBox {
        display: flex;
        align-items: center;
        width: 800px;
        height: 47px;
        background: #ffffff;
        border: 1px solid #ff6900;
        border-radius: 3px;
        input {
          border: none;
          height: 47px;
          // padding-left: 21px;
          margin-left: 21px;
          width: 708px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #999999;
        }
        .shoppingMall-btnBox {
          height: 100%;
          // height: 40px;
          padding: 0 10px;
          background: #ff6900;
          border-radius: 0 0px 0px 0;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          > img:first-child {
            width: 52px;
            height: 22px;
            margin: 0 10px;
          }
          i {
            width: 16px;
            height: 16px;
            @include backgroundGroup("@~assets/images/shoppingMall/search.png");
          }
        }
      }
      .shoppingMall-btnGroup {
        margin-left: 70px;
        display: flex;
        cursor: pointer;
        align-items: center;
        .shoppingMall-btn {
          width: 164px;
          height: 46px;
          background: #ffffff;
          border: 1px solid #f0f1f3;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 19px;
          position: relative;
          .badge {
            position: absolute;
            width: 23px;
            height: 17px;
            left: 40px;
            top: 5px;
            @include backgroundGroup("@~assets/images/shoppingMall/badge.png");
          }
          .btn-icon {
            width: 22px;
            height: 22px;
            margin-right: 8px;
          }
          .shoppingCart {
            @include backgroundGroup(
              "@~assets/images/shoppingMall/shoppingCart.png"
            );
          }
          .order {
            @include backgroundGroup("@~assets/images/shoppingMall/order.png");
          }
          .btn-text {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #ff6900;
          }
        }
      }
    }
    &-bottom {
      margin-top: 13px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .bottom-text {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
        margin-right: 31px;
        cursor: pointer;
      }
      .active {
        color: #ff6900;
      }
    }
  }
  .shoppingMall-banner {
    // height: 500px;
    display: flex;

    ::v-deep .el-menu {
      width: 237px;
      img {
        width: 19px;
        height: 19px;
        margin-right: 13px;
        image-rendering: -webkit-optimize-contrast;
      }
    }

    &-nav {
      width: 237px;
      height: 100%;
      background: #ffffff;
      border-radius: 8px;
      margin-right: 13px;
      position: relative;
      &-item {
        line-height: 50px;
        display: flex;
        width: 237px;
        align-items: center;
        img {
          width: 19px;
          height: 19px;
          margin-left: 51px;
          margin-right: 13px;
          image-rendering: -webkit-optimize-contrast;
        }
        .nav-item-text {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
          cursor: pointer;
        }
        &:hover {
          background: #e1ecff;
        }
      }
      .shoppingMall-banner-navBox {
        position: absolute;
        top: 0px;
        left: 237px;
        width: 1010px;
        // height: 500px;
        background: #fff;
        box-shadow: 0px 2px 19px 2px rgba(155, 162, 173, 0.09);
        border-radius: 8px;
        z-index: 200;
        .navBox-item {
          display: flex;
          margin: 30px 0 0 30px;
          line-height: 30px;
          .navBox-item-label {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            width: 80px;
            span {
              cursor: pointer;
            }
          }
          i {
            margin: 0 30px 0 0;
            line-height: 30px;
          }
          .navBox-item-content {
            display: flex;
            flex-wrap: wrap;
            width: 800px;
            line-height: 50px;
            .navBox-item-content-item {
              line-height: 18px;
              margin-right: 27px;
              width: 100px;
              line-height: 30px;
              @include ellipsis();
              span {
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #666666;
                cursor: pointer;
                &:hover {
                  color: #ff6900;
                }
              }
            }
          }
        }
      }
    }
    &-swiper {
      flex: 1;
      height: 100%;
      ::v-deep .el-carousel {
        width: 100%;
        img {
          image-rendering: -webkit-optimize-contrast;
          width: 100%;
          height: 100%;
        }
        .el-carousel__indicators {
          .el-carousel__indicator {
            .el-carousel__button {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: #fff;
              opacity: 1;
            }
          }
          .is-active {
            .el-carousel__button {
              background: #ff6900;
            }
          }
        }
      }
    }
  }
  .shoppingMall-recommend {
    margin-top: 44px;
    &-title {
      text-align: center;
      font-size: 34px;
      font-family: PingFang SC;
      font-weight: 800;
      color: #333333;
    }
    &-content {
      margin-top: 42px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 76px;
      background: #ffffff;
      border-radius: 8px;
      .recommend-content-item {
        flex: 1;
        height: 20px;
        border-right: 2px solid #c8dcff;
        display: flex;
        justify-content: center;
        align-items: center;
        .item-text {
          cursor: pointer;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
        }
        .active {
          padding: 0 20px;
          height: 40px;
          line-height: 40px;
          background: #ff6900;
          border-radius: 20px;
          color: #fff;
        }
        &:last-child {
          border: none;
        }
      }
    }
  }
  .shoppingMall-sortBox {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    .right-item {
      display: flex;
      margin-right: 20px;
      cursor: pointer;

      .right-item-text {
        font-size: $font-size-medium;

        font-weight: 500;
        color: $color-text-black;
      }

      .right-item-icon {
        width: 9px;
        height: 13px;
        margin-left: 6px;
      }

      .up {
        @include backgroundGroup(
          "~assets/images/goldenSentence/sortIcon-blue-up.png"
        );
      }

      .down {
        @include backgroundGroup(
          "~assets/images/goldenSentence/sortIcon-blue.png"
        );
      }

      .fontColor {
        color: #ff6900;
      }
    }
    .sort {
      display: flex;
    }
    .shoppingMall-sort {
      display: flex;
      align-items: center;
      .shoppingMall-sort-item {
        margin-right: 30px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        display: flex;
        align-items: center;
        cursor: pointer;
        .sortItem-icon {
          width: 9px;
          height: 13px;
          margin-left: 10px;
          @include backgroundGroup("@~assets/images/shoppingMall/default.png");
        }
        .up {
          @include backgroundGroup("@~assets/images/shoppingMall/up.png");
        }
        .down {
          @include backgroundGroup("@~assets/images/shoppingMall/down.png");
        }
      }
    }
    .shoppingMall-total {
      span {
        color: #ff6900;
        margin-right: 10px;
      }
    }
  }
  .shoppingMall-content {
    position: relative;
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &-item {
      width: 250px;

      // margin-right: 60px;
      margin-top: 30px;
      background: #fff;
      cursor: pointer;
      img {
        image-rendering: -webkit-optimize-contrast;
        width: 100%;
      }
      .item-title {
        display: flex;
        margin-top: 20px;
        margin-left: 24px;
        .item-title-left {
          width: 30px;
          height: 16px;
          background: #eb441e;
          border-radius: 2px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          line-height: 16px;
          margin-right: 14px;
        }
        .item-title-text {
          flex: 1;
          line-height: 1.2;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
        }
      }
      .item-price {
        margin: 20px 24px 20px;

        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #eb441e;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .vip_proces {
          border-radius: 2px;
          padding: 5px;
          border: 1px solid #ec5c3e;
          font-size: 12px;
        }
      }
      .item-business {
        margin-left: 24px;
        margin-top: 19px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
    .advice {
      width: 250px;
      height: 0px;
    }
  }
  .intelli-recom {
    ::v-deep .el-dialog {
      @include flex-center(column);
      justify-content: stretch;
      align-items: stretch;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 0 !important;
      width: 650px;
      height: 611px;
      background: #ffffff;
      box-shadow: 0px 3px 15px 1px rgba(98, 102, 107, 0.24);
      .el-dialog__header {
        padding: 12px;
        .el-dialog__headerbtn {
          top: 8px;
          right: 12px;
        }
      }
      .el-dialog__body {
        @include flex-center(column);
        justify-content: stretch;
        align-items: stretch;
        flex: 1;
        padding: 0;
        .title {
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          color: #333333;
        }
        .desc {
          margin-top: 11px;
          text-align: center;
          font-size: 12px;
          font-weight: 500;
          color: #999999;
          .blue {
            color: #ff6900;
            cursor: pointer;
          }
        }
        .idiom {
          max-height: 550px;
        }
      }
    }
  }

  .kong {
    position: relative;
    margin: 170px 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      image-rendering: -webkit-optimize-contrast;
      margin-bottom: 20px;
    }
  }
}
</style>
